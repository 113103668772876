import React, { useState, useEffect } from "react"
import { Row, Col, Container } from "react-bootstrap"
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import SEO from "../components/seo"
import arrowDown from "./../images/icons/arrowDown.png"
import Footer from "../components/Footer"
import Main from "../components/Cattaneo/Main"
import styles from "./../scss/pages/_esport.module.scss"
import Form from "../components/Cattaneo/Form"
import Success from "../components/Cattaneo/Success"
import AgeGate from "../components/AgeGate"
import Cookies from "js-cookie"
import styles2 from "./../scss/components/Cattaneo/_Main.module.scss"

export default function Thebirra() {
  const [age, setAge] = useState(true)

  useEffect(() => {
    // console.log("ok!")
    let cookie = Cookies.get("age")
    let local = sessionStorage.getItem("age")
    // console.log("Cookie",cookie)
    if (cookie || local) setAge(false)
  }, [])
  return !age ? (
    <Router>
      <Route exact path="/cattaneo">
        <div styles={{ overflow: "hidden" }}>
          <SEO title="DJ Cattaneo" />
          <Container fluid className="m-0 p-0">
            <div className={styles2.backgroundImg}>

            <section>
              <Main />
            </section>
            <Row className="d-flex justify-content-center m-0">
              <Col xs={"auto"}>
                <a
                  onClick={e =>
                    document
                      .querySelector("#FormSection")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                >
                  <img src={arrowDown} className={styles.ArrowDownSection} />
                </a>
              </Col>
            </Row>
            <section id="FormSection">
              <Form />
            </section>
            </div>
            <Footer />
          </Container>
        </div>
      </Route>
      <Route path="/cattaneo/success">
        <Success />
      </Route>
    </Router>
  ) : (
    <AgeGate path="/cattaneo" />
  )
}
