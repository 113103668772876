import React from "react"
import SEO from "../seo"
import Footer from "../Footer"
import styles from "./../../scss/pages/_cattaneo.module.scss"

import logo from "./../../images/Esports/logo-bud.svg"
import { Container } from "react-bootstrap"

export default function Success() {
  return (
    <div styles={{ overflow: "hidden" }}>
      <SEO title="Cattaneo" />
      <Container fluid className="m-0 p-0">
        <section>
          <div className={styles.wrap}>
            <div className={styles.backgroundCol}>
              <div className={styles.container}>
                <div>
                  <img src={logo} className={styles.logo} />
                </div>
                <div className={styles.wrapText}>
                  <div className={styles.titleContainer}>
                    <h1 className={styles.title}>GRACIAS!</h1>
                  </div>
                  <h1 className={styles.infoTextOne}>
                    Seguí disfrutando del show de Cattaneo <br /> junto a
                    Budweiser. <br /> THE BIRRA BEHIND THE MUSIC
                  </h1>
                </div>
                <div>
                  <h1 className={styles.infoTextTwo}>
                    Seguinos en Twitter y entérate de <br />
                    todas las novedades
                  </h1>
                  <a
                    className={styles.btnContainer}
                    target="_blank"
                    href="https://twitter.com/budargentina"
                  >
                    <div className={styles.followBtn}>Seguirnos</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={styles.footer}>
          <Footer />
        </div>
      </Container>
    </div>
  )
}
